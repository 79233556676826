<template>
    <div :style="{'--inputBg': inputBg, '--buttonColor': buttonColor, '--buttonColorDark': buttonColorDark, '--fontColor': fontColor}">
        <div v-if="status === 'unauthorized'" class="login">
            <div class="headline">
                <slot></slot>
            </div>
            <div class="form">
                <label for="email">Email address</label>
                <input placeholder="richard@piedpiper.com" v-model="email" autocomplete="email" id="email"/>
                <span><font-awesome-icon icon="question" class="form-info-icon"/>You will receive a one-time login link</span>
            </div>
            <div class="submit">
                <button class="primary-button login-button" @click="login">Login<font-awesome-icon icon="arrow-right" class="login-arrow"/></button>
                <a href="/signup">MailPal is still in Beta, learn more here</a>
            </div>
        </div>
        <div v-else-if="status === 'verify'" class="verify">
            <h1>Login email sent to:</h1>
            <span>{{email}}</span>
            <p>Click on the link in the email or enter the code:</p>
            <input v-model="code" placeholder="abcdef" autocomplete="one-time-code">
            <button class="primary-button verify-button" @click="checkCode">Check</button>
        </div>
        <div v-else-if="status === 'success'" class="verify">
            <h1>Successfull login!</h1>
            <p>If you are not redirected after 5s, click <a href="/">here</a></p>
        </div>
        <div v-else-if="status === 'loading'" class="loading">
            <p>Please wait...</p>
            <loading-icon />
        </div>
        <div v-else-if="status === 'error'" class="error">
            <h1>Error</h1>
            <p>{{errorMsg}}</p>
            <a href="/login">Go back</a>
        </div>
    </div>
</template>

<script>
    import { Action, Mutation } from '../store/auth/types'
    import LoadingIcon from '@/components/LoadingIcon'

    export default {
        name: 'Login',
        props: {
            inputBg: {
                type: String,
                default: "var(--background)"
            },
            buttonColor: {
                type: String,
                default: "var(--primary)"
            },
            buttonColorDark: {
                type: String,
                default: "var(--primary-dark)"
            },
            fontColor: {
                type: String,
                default: "var(--font-dark)"
            }
        },
        computed: {
            email: {
                get: function(){
                    return this.$store.state.auth.email;
                },
                set: function(value){
                    this.$store.commit(Mutation.UPDATE_EMAIL, value); 
                }
            },
            code: {
                get: function(){
                    return this.$store.state.auth.code;
                },
                set: function(value){
                    this.$store.commit(Mutation.UPDATE_CODE, value); 
                }
            },
            status: function(){
                return this.$store.state.auth.status;
            },
            errorMsg: function(){
                return this.$store.state.auth.errorMsg;
            }
        },
        methods: {
            login(){
                this.$store.dispatch(Action.LOGIN);
            },
            checkCode(){
                this.$store.dispatch(Action.CHECK_CODE, "login")
                .then(() => {
                    this.$router.replace({ path: '/' })
                })
            }
        },
        components: {
            LoadingIcon
        },
        watch: {
            status: function(newValue){
                switch(newValue){
                    case "verify":
                        document.title = "Login email sent - MailPal";
                        break;
                    case "logged in":
                        document.title = "Login success - MailPal";
                        break;
                    case "loading":
                        document.title = "Loading - MailPal"
                        break;
                    case "error":
                        document.title = "Error - MailPal"
                        break;
                    default:
                        document.title = "Login - MailPal";
                }
            },
        }
    }
</script>

<style>

    .verify{
        text-align: center;
    }

    .verify p{
        font-weight: 650;
        font-size: 15px;
    }

    .verify-button{
        background: var(--buttonColor);
        color: var(--fontColor);
        font-size: 19px;
        border-bottom: 5px solid var(--buttonColorDark);
        display: block;
        margin: auto;
        margin-top: 1rem;
    }

    .verify input{
        border: none;
        border-radius: 5px;
        background: var(--inputBg);
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
        color: var(--font);
        font-weight: 700;
        padding: 15px 15px;
        margin-top: 10px;
        font-size: 15px;
    }

    .verify span{
        color: var(--primary);
        font-weight: 700;
    }

    .verify a{
        text-decoration: none;
        color: var(--primary);
    }

    .headline{
        text-align: center;
    }

    .headline h1{
        margin-bottom: 0;
    }

    .headline p{
        color: var(--font-dark);
        font-weight: 700px;
        font-size: 18px;
        margin-top: 0;
    }

    .form{
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 85%;
    }

    .form label{
        font-size: 18px;
    }

    .form input{
        border: none;
        border-radius: 5px;
        background: var(--inputBg);
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
        color: var(--font);
        font-weight: 700;
        padding: 15px 15px;
        margin-top: 10px;
        font-size: 15px;
    }

    .form input:focus{
        filter: brightness(105%);
    }

    ::placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    ::-webkit-input-placeholder {
        color: var(--font-dark);
        font-weight: 700;
    },

    :-ms-input-placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    .form span{
        font-size: 12px;
        color: var(--font-light);
        margin-left: 5px;
        margin-top: 5px;
    }

    .form-info-icon{
        margin-right: 5px;
    }

    .submit{
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
    }

    .submit button{
        align-self: center;
    }

    .submit a{
        align-self: center;
        color: var(--font-light);
        text-decoration: none;
        font-size: 13px;
        margin-top: 0.6rem;
    }

    .submit a:hover{
        text-decoration: underline;
    }

    .primary-button {
        -moz-appearance: none;
        appearance: none;
        border: 0;
        padding: 8px 20px;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
    }

    .primary-button:active {
        transform: scale(0.95);
    }

    .login-button {
        background: var(--buttonColor);
        color: var(--fontColor);
        font-size: 19px;
        border-bottom: 5px solid var(--buttonColorDark);
        margin-top: 1rem;
    }

    .login-arrow{
        margin-left: 7px;
        margin-right: -5px;
    }

    .loading{
        position: relative;
    }

    .loading p{
        margin: auto;
        text-align: center;
        margin-bottom: 1rem;
        font-weight: 700;
    }

    .error{
        text-align: center;
    }

    .error h1{
        margin-top: 0rem;
    }

    .error p{
        font-weight: 650;
        font-size: 15px;
    }

    .error a{
        text-decoration: none;
        color: var(--primary);
    }

    @media only screen and (max-width: 500px) {
        .login-content{
            width: 80%;
            top: 50%;
        }

        .login-logo{
            display: none;
        }

        .form{
            width: 90%;
        }

        .verify p{
            font-size: 15px;
        }

        .verify h1{
            font-size: 22px;
        }
    }

</style>