<template>
    <main class="content-wrapper">
        <h1 class="login-logo" @click="backHome">MailPal</h1>
        <div class="login-content">
            <login>
                <h1>Beta Login</h1>
                <p>Welcome back!</p>
            </login>
        </div>
    </main>
</template>

<script>
    import Login from '@/components/Login'

    export default {
        name: 'LoginPage',
        components: {
            Login
        },
        methods: {
            backHome(){
                window.location.href = 'https://mailpal.cc'
            }
        },
        created(){
            document.body.parentNode.style.background = "var(--background)";
            if(this.$store.getters.isLoggedIn){
                this.$router.replace('/')
            }
            document.title = "Login - MailPal";
        }
    }
</script>

<style>
    html,
    body {
        color: var(--font);
        background: var(--background);
    }  

    .login-logo{
        position: absolute;
        top: 5px;
        left: 30px;
        cursor: pointer;
    }

    .login-content{
        width: 400px;
        background: var(--background-sidemenu);
        border-radius: 5px;
        margin: auto;
        padding: 20px 20px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
    }

</style>